import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import Message from '@/models/Components/MessageBubble/Message';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import moment from 'moment';
import store from '@/store';

@Component
export default class MessageBubble extends Vue {
    @Prop() public userMessage!: boolean;
    @Prop() public elementId!: string;
    @Prop() public messages!: Message[];
    @Prop() public formName!: string;
    @Prop() public workflowName!: string;
    @Prop() public actionId?: number;
    @Prop() public formData!: LooseObject;
    @Prop() public disabled!: boolean;
    @Prop() public requestDate!: Date;
    @Prop() public responseDate!: Date;
    @Ref('messageBubble') private messageBubble!: HTMLElement;

    private isDisabled: boolean = this.disabled || false;

    protected created() {
        EventBus.$on('disableMessage', (messageId: string) => this.disableMessage(messageId));
        for (const message in this.messages) {
            if (this.messages[message].value.data && !this.messages[message].value.data.startsWith('data')) {
                this.messages[message].value.data = 'data:' + this.messages[message].value.type + ';base64,'
                    + this.messages[message].value.data;
            }
        }
    }
    get date() {
        let date;
        store.state.messages.forEach((message) => {
            if (message.elementId === this.elementId && (message.responseDate || message.requestDate)) {
                date = (this.userMessage ? message.responseDate : message.requestDate) + 'Z';
            }
        });
        return moment(date).format('MM/DD/YYYY HH:mm');
    }

    get cssClass() {
        return this.userMessage ? 'user-date-time' : 'app-date-time';
    }

    protected updated() {
        this.$nextTick(() => {
            EventBus.$emit('updatedMessageBubble', this.messageBubble.offsetTop);
        });
    }

    private editForm() {
        if (!this.isDisabled) {
            EventBus.$emit(
                'editPreviousFormData',
                this.formName,
                this.workflowName,
                this.formData,
                this.elementId,
                this.actionId,
            );
        } else {
            return;
        }
    }

    private disableMessage(messageId: string) {
        if (this.elementId === messageId) {
            this.isDisabled = true;
            this.$store.commit('disableMessage', messageId);
        }
    }
}
